<template>
  <div>
    <v-card elevation="0">
      <v-card-text class="pa-0">
        <v-container>
          <v-row align="center" justify="center" class="text-center ">
            <v-col>
              <v-icon size="90" color="#27AE60">
                $checkCircleFilledIcon
              </v-icon>
            </v-col>
            <v-col cols="12">
              <div class="success-message my-5">
                <p class="mb-2">パスワードが更新されました。</p>
                <p>次回から新しいパスワードでログインできます。</p>
              </div>
            </v-col>
            <v-col cols="12" class="login-link pt-5">
              <router-link to="/login">
                ログイン画面
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ResetPasswordSuccessPage",
  components: {},
  data() {
    return {
      email: ""
    };
  },
  methods: {}
};
</script>

<style scoped>
.form-header {
  color: #555555 !important;
}
.submit-btn {
  font-size: 16px !important;
  color: #333 !important;
  font-weight: 700;
  height: 50px !important;
}
.success-message {
  color: #555;
  font-size: 14px;
}
.message-block {
  background-color: #f2f2f2;
  border-radius: 5px;
  color: #858585;
  font-size: 12px;
}
.login-link {
  color: #2f80ed !important;
  font-weight: 700;
}
</style>
