<template>
  <div>
    <div v-if="success">
      <ResetPasswordSuccessPage />
    </div>
    <div v-else>
      <ResetPasswordPage />
    </div>
  </div>
</template>

<script>
import ResetPasswordPage from "@/components/layout/auth/ResetPasswordPage";
import ResetPasswordSuccessPage from "@/components/layout/auth/ResetPasswordSuccessPage";
export default {
  name: "ForgotPassword",
  components: { ResetPasswordPage, ResetPasswordSuccessPage },
  props: {
    success: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped></style>
