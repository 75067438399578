<template>
  <v-container class="px-0">
    <v-card elevation="0">
      <v-card-text>
        <v-container>
          <validation-observer ref="observer">
            <form @submit.prevent="submit">
              <v-row align="center" justify="center">
                <!-- <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <div class="form-header mb-2">
                      {{ $t("enter_your_registered_email") }}
                    </div>
                    <input-email
                      v-model="email"
                      :label="$t('enter_your_email')"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                    />
                  </validation-provider>
                </v-col> -->

                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="パスワード"
                    vid="password"
                    rules="required|long_password"
                  >
                    <div class="form-header mb-2">新しいパスワード</div>
                    <input-password
                      v-model="password"
                      :label="$t('enter_your_new_password')"
                      placeholder="パスワード"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="パスワード"
                    rules="required|password_confirmed:password"
                  >
                    <div class="form-header mb-2">パスワードの再入力</div>
                    <input-password
                      v-model="confirm_password"
                      :label="$t('enter_your_new_password_confirmation')"
                      placeholder="パスワード"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    color="#F2C94C"
                    class="submit-btn"
                    block
                    @click="submit"
                    :disabled="getApiProcessingStatus"
                    :loading="getApiProcessingStatus"
                  >
                    {{ $t("reset_password") }}
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// import InputEmail from "@/components/ui/InputEmail";
import InputPassword from "@/components/ui/InputPassword";
import { mapGetters } from "vuex";
export default {
  name: "ResetPasswordPage",
  components: { InputPassword },
  data() {
    return {
      // email: "",
      password: "",
      confirm_password: ""
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
    email() {
      return this.$route.params.email;
    },
    ...mapGetters(["getApiProcessingStatus"])
  },
  methods: {
    submit() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        let email = this.email;
        let password = this.password;
        let password_confirmation = this.confirm_password;
        let token = this.token;

        this.$store
          .dispatch("AUTH_RESET", {
            email,
            password_confirmation,
            password,
            token
          })
          .then(
            response => {
              if (response.status === 200) {
                this.$router.push({
                  query: { success: true }
                });
              }
            },
            error => {
              console.error(error);
            }
          )
          .catch(err => console.log(err));
      });
    }
  }
};
</script>

<style scoped>
.form-header {
  color: #555555 !important;
}

.submit-btn {
  font-size: 16px !important;
  color: #333 !important;
  font-weight: 700;
  height: 50px !important;
}
</style>
